@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
.worker_card{
    border: 1px solid rgb(149, 144, 144);
    padding: 10px;
    border-radius: 10px;
    width: 350px;
    height: 210px;
    cursor: pointer;
}

.worker_card p{
    margin: 1px;
}

.duration{
    background-color: #007bff;
    border-radius: 5px;
    padding: 2px 10px;
    font-size: 15px;
    font-weight: 600;
    width: fit-content;

}

.card_icon{
    display: flex;
    justify-content: space-between;
}

.card_icon i{
    font-size: 20px;
}

.bi-send{
    color: #007bff;
}

.posted_on{
  font-size: 13px;
  color: rgb(104, 101, 101);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

@media only screen and (max-width: 600px) {
    .card_center{
        justify-content: center;
    }
  }