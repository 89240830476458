.carousel-container {
    max-width: 100%;
    margin: 0 auto;
  }

  .hero_banner{
    width: 100%;
  }

  .category_icons img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid orange;
  }
  
  .carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
  
  .team-member {
    flex: 0 0 auto;
    width: 300px;
    margin-right: 50px;
    scroll-snap-align: start;
    box-shadow: 3px 3px 10px grey;
    border-radius: 20px;
    text-align: center;
  }
  
  .team-member img {
    width: 60%;
    height: auto;
  }
  
  .team-member h4, .team-member p {
    text-align: center;
    margin: 10px 0;
  }
  
  .about-us-image img{
    width: 100%;
  }

.team-member{
  background-color: rgb(108, 99, 255);
  padding-top: 40px;
}

.find_worker{
    border-radius: 10px;
    font-weight: bold;
    width: 130px;
    display: flex;
    justify-content: center;
    border: 2px solid rgb(108, 99, 255);
    text-decoration: none;
    padding: 10px 10px;
    margin-top : 10px;
    cursor: pointer;
}

.find_worker:hover{
    color: black;
}

.why_choose_us{
  display: flex;
  justify-content: center;
}

.bg-orange{
  background-color: #FF914C;
  color: #fff;
}

.All_Categories_btn{
  border-radius: 10px;
  font-weight: bold;
  width: 180px;
  display: flex;
  justify-content: center;
  border: 2px solid rgb(108, 99, 255);
  text-decoration: none;
  padding: 10px 10px;
  margin-top : 10px;
  cursor: pointer;
}

.phone-ss img{
  width: 90%;
}

.define-steps li{
padding: 5px 0;
}

@media only screen and (max-width: 768px) {
  .border-banner{
    border: 2px solid orange;
  }

  .category_icons img{
    width: 60px;
    height: 60px;
  }

  .category_icons h6{
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {



}
