.Get_App{
    /* background-color: rgb(108, 99, 255); */
    border-radius: 10px;
    font-weight: bold;
    width: 110px;
    display: flex;
    justify-content: center;
    border: 2px solid rgb(108, 99, 255);;
}

.Get_App:hover{
    cursor: pointer;
}

.Get_App a{
    color: rgb(108, 99, 255);
}

.logo{
    color: rgb(108, 99, 255);
    font-weight: bold;
    font-size: 22px;
    display: flex;
    align-items: center;
}
.logo:hover{
    color: rgb(108, 99, 255);
}
.nav-link{
    color:rgb(108, 99, 255);
    font-weight: 600;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom:0;
    padding-top:0;
}
.navbar-pill{
    color: #fff !important;
    font-weight: bold;
    border-bottom:2px solid #FF904C ;

}
.navbar-mobile{
    color: rgb(108, 99, 255) !important;
}

.active{
   font-weight: bolder; 
   color: #fff;
}

.bg-nav{
    background-color: rgb(108, 99, 255);
}

#navbarNav{
    justify-content: end;
}

.top-navbar-icon{
    border: none;
    background-color: rgb(108, 99, 255);
}

.top-navbar-icon i{
    color: #fff;
    font-size: 22px;
}

.top-navbar-icon div{
    max-width: 120px;
    display: flex;
    justify-content: space-between;
}

.home_icon, .worker_icon, .profile_icon{
    background-color: rgb(108, 99, 255);
    padding: 8px 12px;
    border-radius: 50%;
}

.home_icon i, .worker_icon i, .profile_icon i{
  color: #fff;
}

.bottom-nav{
  border-radius: 20px 20px 0 0;
  border: 1px solid rgb(205, 192, 192);
}

.nav-item .text-black{
    color: #fff !important;
}


@media only screen and (min-width: 768px) {
    .bottom-nav-section{
        display: none;
    }

    .top-navbar-icon{
        display: none;
    }
  }

.img-fit{
    object-fit: cover;
}

  
