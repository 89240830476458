.form-section {
  max-width: 400px;
  margin: auto;
}

.form-section form {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-section label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-section input[type="email"],
.form-section textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-section textarea {
  height: 150px; /* Set the height of the textarea */
}

.form-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-section button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.validation-error {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 5px;
}

.form-bg{
  background-color: #007bff;
}

.contact-us-icons a{
  padding: 0 10px;
}

.contact-details li {
  font-size: 18px;
  padding: 5px 0;
}

.blue-first-letter::first-letter {
  color: blue;
}

.gif img{
width: 80%;
}

@media only screen and (max-width: 768px) {

  .gif img{
    width: 60%;
    }

    .gif{
      text-align: center;
      margin: 10px 0 20px 0;
      }

    .contact-details{
      text-align: center;
    }

    .blue-first-letter{
      text-align: center;
    }

    ul li{
      list-style: none;
    }
}

@media only screen and (max-width: 456px) {

  .gif img{
    width: 90%;
    }
}