.blog_image img{
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.img-fit{
    object-fit: cover;
}

.blog-container{
    width: 32%;
    margin: 5px;
}

@media only screen and (max-width: 768px) {
    .blog-container{
        width: 48%;
        margin: 2px;
    }
  }

  @media only screen and (max-width: 425px) {
    .blog-container{
        width: 100%;
        margin: 0px;
    }
  }