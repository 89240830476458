

.form-control{
    box-shadow: none !important;
    height: 50px;
}

.bg-color{
    background-color: rgb(108, 99, 255); 
 }
.color{
    color: rgb(108, 99, 255); 
 }

 /* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:rgb(108, 99, 255);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(108, 99, 255);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(108, 99, 255);
  }
  .content-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cursor{
    cursor: pointer;
  }