@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
.worker_card{
    border: 1px solid rgb(149, 144, 144);
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-name-font{
 font-size: 13px;
}

.card_detail_sec{
    display: flex;
    align-items: center;
}

.worker_image img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid grey;
}

.worker_card p{
    margin: 1px;
}

.card_icon i{
    font-size: 20px;
}

.category_icon i{
    font-size: 25px;
  }

.search_bar{
    width: 70%;
  }

  .btn-outline-success{
    width: 30%;
  }

  .whatsapp_phone_icon{
    display: flex;
  }

  .whatsapp_phone_icon div{
    padding: 0 10px 0 10px;
  }

  .search_bar{
    width: 75%;
  }

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .carousel-caption p{
    font-size: 20px;
  }

  .carousel-item{
    justify-content: center;
  }

  .category_icons_section{
    width: 120px;
    background-color: #007bff;
  }

  .category_icons_section img{
    width: 80px;
    border-radius: 50px;
  }

  .button_bg{
    background-color: rgb(108, 99, 255);
    color: white;
    border: none;
    width: 20%;
  }

  .category_icons{
    text-align: center;
  }

  .show_before_600{
    display: none;
  }

  .show_after_600{
    display: block;
  }

  .whatsapp_color{
    color: #25D366;
  }

  .bi-telephone{
    color: rgb(108, 99, 255);
  }

  .hindi_category{
    font-size: 14px;
  }

  @media only screen and (max-width: 600px) {
    .card_center{
        justify-content: center;
    }

    .worker_card{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .category_Name{
        font-size: 11px;
    }

    .category_icon i{
        font-size: 20px;
      }

      .whatsapp_phone_icon{
        display: flex;
      }

      .search_bar{
        width: 100%;
      }

      .show_before_600{
        display: block;
      }

      .category_icons{
        margin: 10px 0;
      }

      .hindi_category{
        font-size: 11px;
      }
  }
  